.video-container {
    position: relative;
    width: 100%;
    height: 100vh;
    z-index: -1;

}

.video {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.content {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-family: Quicksand;
    text-align: left;
    display: flex !important;
    justify-content: flex-end;
    align-items: flex-start;
    flex-direction: column;
    width: 100%;
    height: 100%;
    padding: 0 1.5rem;
}

.content h1 {
    font-size: 3.5rem;
    font-weight: 700;
    margin-bottom: 1rem;
}

.content button {
    font-size: 1.5rem;
    font-weight: 700;
    padding: 0.5rem 1rem;
    border: 1px solid white;
    border-radius: 5px;
    background-color: transparent;
    color: white;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    font-family: quicksand;
    margin-top: 20px;
    margin-bottom: 20px;

    
}

@media screen and (max-width: 768px) {
    .content h1 {
        font-size: 2.5rem;
    }
    .content p {
        font-size: 1rem;
        width: 70%;
    }
    .content button {
        font-size: 1rem;
    }
}

    