footer {
    border-top: 1px solid black;
}

.iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin-top: 20px;
    font-size: 20px;
}

.iconSm {
    margin: 0 10px;
}

.ulFooter {
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    margin-top: 10px;
    font-size: 15px;
}

.footer-item {
    margin: 0 10px;
}

.footer-item a {
    text-decoration: none;
    color: black;
    font-family: Quicksand;
}

.footer-item a:hover {
    color: #787878;
}

.Copyrights {
    font-family: Quicksand;
    font-size: 15px;
    padding: 0;
    text-align: center;
    margin-top: 10px;
    font-size: small;
    margin-bottom: 10px;
}