.navbar {
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #000000;
  }
  
  .navbar-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    z-index: 1;
  }
  .navbar-logo {
    color: #000000;
    text-decoration: none;
    font-family: QuickSand, sans-serif;
    margin-left: 20px;
    font-size: 20px;
    font-weight: bolder;
  }
  
  .menu-icon {
    display: none;
    color: #000000;
    cursor: pointer;
    font-size: 20px;
    font-family: QuickSand, sans-serif;
    margin-right: 20px;
  }
  
  .nav-menu {
    display: flex;
    align-items: center;
    list-style: none;
    z-index: 1;
  }
  
  .linkContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    width: 100%;
    margin-right: 20px;
}

    .linkContainer a {
        font-family: QuickSand, sans-serif;
        text-decoration: none;
        color: #000000;
        margin-left: 20px;
        font-size: 16px;
    }

    .linkContainer a:hover {
        color: #000000;
        text-decoration: underline;
    }

    .SocialMedia {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-right: 20px;

    }

    .icon{
        font-size: 18px;
        text-decoration: none;
        color: #000000;
        margin-left: 10px;
    }
  /* Media Query for the mobile menu */
  @media screen and (max-width: 519px) {
    .menu-icon {
      display: block;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      height: 100vh;
      position: absolute;
      top: 50px;
      left: -100%;
      opacity: 0;
      visibility: hidden;
      transition: all 0.3s ease;
      background: #ffffff;
      padding-left: 24px;
      padding-top: 50px ;

    }
  
    .nav-menu.active {
      left: 0;
      opacity: 1;
      visibility: visible;
    }
  
    .nav-item {
      margin-bottom: 50px;
    }

    .nav-item a {
      margin-right: 16px;
      font-family: QuickSand, sans-serif;
      font-size: 18px;
      text-decoration: none;
      color: #000000;
  
    }

    .linkContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        margin-right: 20px;
    }
    .SocialMedia {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
  }

  @media screen and (min-width: 520px) and (max-width: 1274px) {
    .linkContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        width: 100%;
        margin-right: 20px;
    }
    .SocialMedia {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
  }
    
  